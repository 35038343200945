import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

interface RegistryState {
  value: boolean
}

const initialState = (): RegistryState => {
  return {
    value: false,
  }
}

const registrySlice = createSlice({
  name: 'registry',
  initialState,
  reducers: {
    setIsReduxInitialized(state, action: PayloadAction<boolean>) {
      state.value = action.payload
    },
  },
  selectors: {
    getIsReduxInitialized: (sliceState) => sliceState.value,
  },
})

export const { setIsReduxInitialized } = registrySlice.actions
export const { getIsReduxInitialized } = registrySlice.selectors
export default registrySlice.reducer
