'use client'

import { configureStore } from '@reduxjs/toolkit'
import { useDispatch, useSelector, useStore } from 'react-redux'
import authProvidersReducer from '@/lib/features/authProviders/authProvidersSlice'
import browseFiltersReducer from '@/lib/features/browseFilters/browseFiltersSlice'
import couponsReducer from './features/coupons/couponsSlice'
import currentUserReducer from '@/lib/features/currentUser/currentUserSlice'
import gendersReducer from './features/genders/gendersSlice'
import generatorSettingsReducer from './features/generatorSettings/generatorSettingsSlice'
import historyFiltersReducer from '@/lib/features/historyFilters/historyFiltersSlice'
import imageGuideTypesReducer from './features/imageGuideTypes/imageGuideTypesSlice'
import membershipPeriodsReducer from './features/membershipPeriods/membershipPeriodsSlice'
import membershipTypesReducer from './features/membershipTypes/membershipTypesSlice'
import orientationTypesReducer from './features/orientationTypes/orientationTypesSlice'
import pricesReducer from './features/prices/pricesSlice'
import purchaseTypesReducer from './features/purchaseTypes/purchaseTypesSlice'
import sizesReducer from './features/sizes/sizesSlice'
import sizeTypesReducer from './features/sizeTypes/sizeTypesSlice'
import styleTypesReducer from './features/styleTypes/styleTypesSlice'
import stylesReducer from './features/styles/stylesSlice'
import tokenReducer from './features/token/tokenSlice'
import verifyTypesReducer from './features/verifyTypes/verifyTypesSlice'
import versionReducer from './features/version/versionSlice'
import registryReducer from '@/lib/features/registry/registrySlice'

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const makeStore = () => {
  return configureStore({
    reducer: {
      authProviders: authProvidersReducer,
      browseFilters: browseFiltersReducer,
      coupons: couponsReducer,
      currentUser: currentUserReducer,
      genders: gendersReducer,
      generatorSettings: generatorSettingsReducer,
      historyFilters: historyFiltersReducer,
      imageGuideTypes: imageGuideTypesReducer,
      membershipPeriods: membershipPeriodsReducer,
      membershipTypes: membershipTypesReducer,
      orientationTypes: orientationTypesReducer,
      prices: pricesReducer,
      purchaseTypes: purchaseTypesReducer,
      sizes: sizesReducer,
      sizeTypes: sizeTypesReducer,
      styleTypes: styleTypesReducer,
      styles: stylesReducer,
      token: tokenReducer,
      verifyTypes: verifyTypesReducer,
      version: versionReducer,
      registry: registryReducer,
    },
    devTools: process.env['NEXT_PUBLIC_PICTRIX_STAGE'] !== 'prod',
  })
}

// Infer the type of makeStore
export type AppStore = ReturnType<typeof makeStore>

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<AppStore['getState']>
export type AppDispatch = AppStore['dispatch']

// Use throughout the app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = useDispatch.withTypes<AppDispatch>()
export const useAppSelector = useSelector.withTypes<RootState>()
export const useAppStore = useStore.withTypes<AppStore>()
